import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
// Chakra imports
import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Card,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import { HiOutlineRefresh } from "react-icons/hi";

import { listSignals } from "graphql/queries";

function TablesSignals() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const [Signals, setSignals] = useState([]);

  const [update, setUpdate] = useState(true);

  async function getAllNotifications() {
    console.log("obteniendo notificaciones...");
    try {
      const getAllNotificationsData = await (
        await API.graphql(graphqlOperation(listSignals))
      ).data;
      if (getAllNotificationsData.listSignals.items != null) {
        const onlyValidNotifications =
          getAllNotificationsData.listSignals.items.filter(
            (item) => !item._deleted,
          );
        setSignals(onlyValidNotifications);
      }
    } catch (error) {
      console.log("Error actualizando usuarios", error);
    }
  }
  useEffect(() => {
    if (update) {
      getAllNotifications();
      setUpdate(false);
    }
  }, [update]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "scroll" }} p="1rem">
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="xl" color={textColor} fontWeight="bold" pb=".5rem">
              Todas las Señales
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  leftIcon={<HiOutlineRefresh />}
                  onClick={() => getAllNotifications()}
                >
                  ACTUALIZAR
                </Button>
              </div>
              <Tr my=".8rem" pl="0px">
                <Th pl="0px" color="gray.400" borderColor={borderColor}>
                  PAR OPERADO
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Precio
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Hora (GMT-5)
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Fecha (GMT-5)
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Tipo de Orden
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Posición
                </Th>

                <Th color="gray.400" borderColor={borderColor}>
                  Acciones
                </Th>

                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {Signals.length > 0 &&
                Signals.map((signal, index, arr) => {
                  if (signal) {
                    return (
                      <TablesProjectRow
                        id={signal.id}
                        pair={signal.pair}
                        title={signal.title}
                        description={signal.subtitle ?? ""}
                        type={signal.mode}
                        setUpdate={setUpdate}
                        price={signal.price}
                        timestamp={signal.timestamp}
                        time12h={new Date(
                          parseInt(signal.timestamp),
                        ).toLocaleTimeString("es-CO", {
                          hour12: false,
                        })}
                        date={new Date(
                          parseInt(signal.timestamp),
                        ).toLocaleDateString()}
                        position={signal.position}
                        isManual={signal.mode === "MANUAL"}
                        createdAt={signal.createdAt}
                        signal={signal}
                        getAllNotifications={getAllNotifications}
                        isLast={index === arr.length - 1 ? true : false}
                        key={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default TablesSignals;
