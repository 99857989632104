import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";

function App() {
  /* if (env === 'production') {
    console.log = function () {};
} */

  return (
    <Router>
      <Switch>
        <Route path={`/`} component={AdminLayout} />
      </Switch>
    </Router>
  );
}

export default App;
