import { API } from "aws-amplify";

export const sendNotification = async (pair) => {
  try {
    await API.post("apiNotifications", "/notificator-staging", {
      body: {
        title: "You have a new signal. Please check the app.",
        body: `New signal for ${pair}`,
      },
    });
  } catch (error) {
    console.error("Error al enviar la notificación:", error);
  }
};
