// chakra imports
import { Avatar, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { ClockIcon } from "components/Icons/Icons";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import React from "react";

export function ItemContentNewPrincipal(props) {
  const navbarIcon = useColorModeValue("gray.500", "gray.200");
  const notificationColor = useColorModeValue("gray.700", "white");
  const spacing = " ";

  if (props.type == "delete") {
    return (
      <Flex onClick={props.onPress} alignItems="center">
        <AiFillDelete color={"#F56565"} size={22} />
        <Flex flexDirection="column">
          <Text
            fontSize="14px"
            ml="1"
            color={notificationColor}
            textAlign="center"
            fontWeight={"bold"}
          >
            Eliminar
          </Text>
        </Flex>
      </Flex>
    );
  }
}
