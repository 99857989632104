import {
  Badge,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { FaEllipsisV } from "react-icons/fa";

import Swal from "sweetalert2";

import { ItemContentNewPrincipal } from "components/Menu/ItemContentNewPrincipal.js";
import { Img } from "react-image";
import { Loader } from "@aws-amplify/ui-react";

function TablesTableRowNews(props) {
  const {
    id,
    title,
    subtitle,
    noticia,
    timestamp,
    type,
    getAllNoticias,
    publishedDate,
    externalUrl,
    portada,
    isLast,
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function deleteNoticia() {
    console.log("eliminando noticia generla con ID", id);
    Swal.fire({
      title: "Estas seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonColor: "#F56565",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const noticiaDetails = {
          id: id,
          _version: noticia._version,
        };
      }
    });
  }

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="wrap">
          <Img
            crossorigin="anonymous"
            style={{ width: "15rem" }}
            borderRadius="100px"
            me="18px"
            src={[portada]}
            loader={<Loader size="lg" />}
          />
          <Flex direction="column" marginLeft={"2"} marginTop={"2"}>
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {title}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {subtitle}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {publishedDate == null ? "N/A" : publishedDate}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Badge
          bg={"blue.400"}
          color={"white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {type}
        </Badge>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {timestamp}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {externalUrl}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {portada}
        </Text>
      </Td>

      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Menu>
          <MenuButton marginLeft={"1rem"}>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList p="16px 8px">
            <Flex flexDirection="column">
              {/* <MenuItem borderRadius='8px' mb='10px'>
                <ItemContentSignals
                  type='edit'
                  onPress={() => console.log("edit signal")}
                />
              </MenuItem> */}
              <MenuItem borderRadius="8px" mb="10px" onClick={deleteNoticia}>
                <ItemContentNewPrincipal
                  type="delete"
                  onPress={deleteNoticia}
                />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesTableRowNews;
